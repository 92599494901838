<template>
  <div class="validation-results">
    <h3 v-if="showInformation">{{ $t('page.titles.information') }}</h3>
    <div v-if="showInformation" class="result-text">
      <p v-for="(information, index) in validationResponse.information"
          :key="index"
          class="info-text">
        <v-icon :color="colors.infoColor.iconColor"
            class="icon">
          mdi-information
        </v-icon>
        <span class="info-text"
            v-html="$t(`messages.${information.key}`)"></span> <!-- njsscan-ignore: vue_template -->
      </p>
    </div>
    <h3>{{ $t('page.titles.results') }}</h3>
    <div class="result-text">
      <div v-if="validationResponse.valid">
        <p class="valid-text">
          <v-icon :color="colors.validColor.iconColor" class="icon">mdi-check-circle</v-icon>
          <span>{{ $t('page.texts.metadata-valid') }}</span>
        </p>
      </div>
      <div v-else>
        <div class="found-violations">
          <span v-html="$tc('page.texts.found-violations', violationCount, {violationCount})"/>
          <v-dialog
              v-model="isModalShown"
              width="600px">
            <template v-slot:activator="{ on, attrs }">
              <a class="modal-link"
                  v-bind="attrs"
                  v-on="on">
                {{ $t('page.buttons.view-list') }}
                <v-icon small>mdi-chevron-right</v-icon>
              </a>
            </template>
            <v-card color="#f5f5f5">
              <v-card-title>{{ $t('page.titles.violations') }}</v-card-title>
              <v-card-text>
                <ul>
                  <li v-for="(violation, index) in validationResponse.violations"
                      :key="index"
                      class="error-text">
                    <v-icon v-if="violation.severity === 'WARNING'"
                        :color="colors.warningColor.iconColor"
                        class="icon">
                      mdi-alert
                    </v-icon>
                    <v-icon v-else-if="violation.severity === 'ERROR'"
                        :color="colors.errorColor.iconColor"
                        class="icon">
                      mdi-alert-circle
                    </v-icon>
                    <span :class="violation.severity === 'WARNING' && 'warning-text'
                        || violation.severity === 'ERROR' && 'error-text'"
                        v-html="$t(`messages.${violation.key}`)"></span> <!-- njsscan-ignore: vue_template -->
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <p v-show="specialErrorMessage" class="error-text">
          <v-icon :color="colors.errorColor.iconColor" class="icon">mdi-alert-circle</v-icon>
          <span>{{ specialErrorMessage }}</span>
        </p>
      </div>
      <v-switch
          v-show="!disableAbstractElement && !validationResponse.valid"
          v-model="hideValid"
          color="#4b525b"
          dense>
        <template v-slot:label>
          <span class="switch-label">{{ $t('page.texts.hide-valid-elements') }}</span>
        </template>
      </v-switch>
    </div>
    <AbstractElement
        :colors="colors"
        :currentNode="rootNode"
        :hideValid="hideValid"
        :isDisabled="disableAbstractElement"
        :validationResponse="validationResponse"
        parentPath=""></AbstractElement>
  </div>
</template>

<script>
import AbstractElement from '@/components/AbstractElement';

export default {
  name: 'ValidationResults',
  props: ['validationResponse'],
  components: {
    AbstractElement
  },
  data() {
    return {
      xmlStr: '',
      specialErrorMessage: '',
      isModalShown: false,
      disableAbstractElement: false,
      hideValid: false,
      showInformation: false,
      colors: {
        validColor: {
          bgColor: '#d9e0d1',
          textColor: 'rgba(0, 0, 0, 0.8)',
          iconColor: '#196629'
        },
        infoColor: {
          bgColor: '#ffffcc',
          textColor: '#1c78bc',
          iconColor: '#1c78bc'
        },
        warningColor: {
          bgColor: '#ffe6cc',
          textColor: '#cc6900',
          iconColor: '#cc6900'
        },
        errorColor: {
          bgColor: '#f5d6d7',
          textColor: '#ca1c33',
          iconColor: '#b4182d'
        }
      }
    };
  },
  computed: {
    rootNode() {
      return (new DOMParser()).parseFromString(this.xmlStr, 'text/xml').documentElement;
    },
    violationCount() {
      return this.validationResponse.violations.length;
    }
  },
  methods: {
    handleValidate() {
      this.specialErrorMessage = '';
      this.disableAbstractElement = false;
      this.showInformation = false;
      const {violations} = this.validationResponse;
      const {information} = this.validationResponse;

      if (information.length) {
        this.showInformation = true;
      }

      if (violations.length) {
        for (let violation of violations) {
          if (violation.key === 'is-saml-two-xml_must-be-valid-according-opensaml-schema-SAML_11'
              || violation.key === 'is-entities-descriptor-xml_exactly-one-entitiesdescriptor') {
            this.specialErrorMessage = violation.message;
            this.disableAbstractElement = true;
            break;
          }
        }

        let checkPath = violations.filter(violation => violation.elementPath);
        if (!checkPath.length) {
          this.disableAbstractElement = true;
        }
      }

      if (this.validationResponse !== '') {
        this.xmlStr = this.validationResponse.data;
      } else {
        this.xmlStr = '';
      }
    }
  },
  mounted() {
    this.handleValidate();
  },
  watch: {
    validationResponse() {
      this.handleValidate();
    }
  }
};
</script>

<style scoped>
.validation-results {
  width: 100%;
  max-width: 1400px;
  margin: 50px auto;
}

.validation-results > h3, .result-text {
  margin: 10px calc(5% + 16px);
}

.result-text {
  font-size: 0.9rem;
  letter-spacing: 0.4px;
}

.found-violations {
  color: darkred;
  margin-bottom: 10px;
}

.switch-label {
  color: #2e3238;
  font-size: 0.9rem;
  letter-spacing: 0.3px;
}

.v-input--switch {
  width: 260px;
}

.info-text {
  color: #1c78bc;
  list-style: none;
  padding: 5px 0;
}

.warning-text {
  color: #cc6900;
  list-style: none;
  padding: 5px 0;
}

.error-text {
  color: #ca1c33;
  list-style: none;
  padding: 5px 0;
}

.valid-text {
  color: #196629;
  padding: 5px 0;
}

.icon {
  margin: 0 10px 0 0;
  padding: 0;
}

.modal-link {
  display: inline-block;
  padding: 3px 5px 3px 8px;
  margin-left: 10px;
  border-radius: 5px;
  font-weight: bold;
  color: darkred;
  background-color: rgba(100, 0, 0, 0.1);
  transition: all .3s ease-out;
}

.modal-link:hover {
  color: #2e3238;
  padding: 3px 10px;
  border-radius: 10px;
  transform: translateX(5px);
}
</style>
