import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import FlagIcon from 'vue-flag-icon';

Vue.config.productionTip = false;

Vue.use(FlagIcon);

new Vue({
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
