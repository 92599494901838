<template>
  <v-app-bar class="page-header" color="blue-grey lighten-5" fixed>
    <h1>{{ $t('page.titles.fink-validation') }}</h1>
    <v-spacer></v-spacer>
    <v-menu class="lang-menu" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="text-none"
            plain
            v-bind="attrs"
            v-on="on">
          <v-icon left small>mdi-web</v-icon>
          <span>{{ selectedLanguage.title }}</span>
          <v-icon small>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
            v-for="(language, index) in languages"
            :key="index"
            @click="changeLanguage(language.value)">
          <flag :iso="language.flag || language.value" class="lang-flag"></flag>
          <v-list-item-title class="lang-title">{{ language.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  name: 'PageHeader',
  data() {
    return {
      selectedLanguage: {},
      languages: [
        {title: 'Deutsch', value: 'de'},
        {title: 'English', value: 'en', flag: 'gb'}
      ]
    };
  },
  methods: {
    changeLanguage(lang) {
      this.selectedLanguage = this.languages.find(l => l.value === lang);
      this.$i18n.locale = lang;
      localStorage.setItem('language', lang);
    }
  },
  created() {
    this.selectedLanguage = this.languages.find(l => l.value === this.$i18n.locale);
  }
};
</script>

<style scoped>
.page-header {
  z-index: 100 !important;
}

h1 {
  font-size: 20px;
}

.lang-menu {
  background-color: #2e3238;
}

.lang-flag {
  font-size: 1.4rem;
}

.lang-title {
  margin-left: 10px;
  font-size: 0.9em;
}
</style>
