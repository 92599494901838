import { render, staticRenderFns } from "./TheDisclaimer.vue?vue&type=template&id=cd2a5972&scoped=true"
import script from "./TheDisclaimer.vue?vue&type=script&lang=js"
export * from "./TheDisclaimer.vue?vue&type=script&lang=js"
import style0 from "./TheDisclaimer.vue?vue&type=style&index=0&id=cd2a5972&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../.yarn/__virtual__/vue-loader-virtual-7cd5c1ca04/2/root/.yarn/vue-loader-npm-15.11.1-829412283a-509a816d45.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd2a5972",
  null
  
)

export default component.exports