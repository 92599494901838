import { render, staticRenderFns } from "./PageHeader.vue?vue&type=template&id=5db1c04a&scoped=true"
import script from "./PageHeader.vue?vue&type=script&lang=js"
export * from "./PageHeader.vue?vue&type=script&lang=js"
import style0 from "./PageHeader.vue?vue&type=style&index=0&id=5db1c04a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../.yarn/__virtual__/vue-loader-virtual-7cd5c1ca04/2/root/.yarn/vue-loader-npm-15.11.1-829412283a-509a816d45.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5db1c04a",
  null
  
)

export default component.exports