<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" max-width="750" persistent>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card class="pa-md-5">
          <v-card-title class="text-h5">
            {{ $t('page.titles.disclaimer') }}
            <v-spacer></v-spacer>
            <v-menu class="lang-menu" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="text-none"
                    plain
                    v-bind="attrs"
                    v-on="on">
                  <v-icon left small>mdi-web</v-icon>
                  <span>{{ selectedLanguage.title }}</span>
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    v-for="(language, index) in languages"
                    :key="index"
                    @click="changeLanguage(language.value)">
                  <flag :iso="language.flag || language.value" class="lang-flag"></flag>
                  <v-list-item-title class="lang-title">{{ language.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-text v-html="$t('page.texts.disclaimer')"/> <!-- njsscan-ignore: vue_template -->
          <v-card-actions>
            <v-list>
              <v-list-item>
                <v-checkbox
                    v-model="read"
                    :label="$t('page.checkbox.read')"
                    :rules="[ (v) => !!v || $t('page.checkbox.warning')]"
                    required/>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                    v-model="accepted"
                    :label="$t('page.checkbox.accepted')"
                    :rules="[ (v) => !!v || $t('page.checkbox.warning')]"
                    required/>
              </v-list-item>
            </v-list>
            <v-spacer></v-spacer>
            <v-btn
                :disabled="!valid"
                color="primary"
                elevation="2"
                rounded
                @click="validate">
              {{ $t('page.buttons.disclaimer') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      read: false,
      accepted: false,
      valid: false,
      showDialog: true,
      selectedLanguage: {},
      languages: [
        {title: 'Deutsch', value: 'de'},
        {title: 'English', value: 'en', flag: 'gb'}
      ]
    };
  },
  methods: {
    validate() {
      if (this.read && this.accepted) {
        this.showDialog = false;
        this.$emit('close', this.showDialog);
      } else {
        this.$refs.form.validate();
      }
    },
    changeLanguage(lang) {
      this.selectedLanguage = this.languages.find(l => l.value === lang);
      this.$i18n.locale = lang;
      localStorage.setItem('language', lang);
    }
  },
  created() {
    this.selectedLanguage = this.languages.find(l => l.value === this.$i18n.locale);
  }
};
</script>

<style scoped>
.lang-menu {
  background-color: #2e3238;
}

.lang-flag {
  font-size: 1.4rem;
}

.lang-title {
  margin-left: 10px;
  font-size: 0.9em;
}
</style>

