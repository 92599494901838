<template>
  <div>
    <v-expansion-panels :disabled="isDisabled" class="abstract-element" flat popout>
      <transition name="collapse">
        <v-expansion-panel v-show="hideValid ? hasWarning || hasError : true"
            :style="boxStyles"
            class="box">
          <v-expansion-panel-header class="label">
          <span>
            <span v-html="label"></span> <!-- njsscan-ignore: vue_template -->
            <v-icon v-if="isValid"
                :color="colors.validColor.iconColor"
                class="label-icon"
                right>mdi-check-circle</v-icon>
            <span v-else>
              <v-icon v-if="hasWarning"
                  :color="colors.warningColor.iconColor"
                  class="label-icon"
                  right>mdi-alert</v-icon>
              <v-icon v-if="hasError || isDisabled"
                  :color="colors.errorColor.iconColor"
                  class="label-icon"
                  right>mdi-alert-circle</v-icon>
            </span>
          </span>
            <template v-slot:actions>
              <v-icon :color="elementColor.textColor">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="!isDisabled">
            <div v-if="violationMessages.length > 0">
              <ul>
                <li v-for="violation in violationMessages"
                    :key="violation.message"
                    :style="(violation.severity === 'WARNING') && warningStyle
                    || (violation.severity === 'ERROR') && errorStyle"
                    class="message-box">
                  <v-icon v-if="violation.severity === 'WARNING'"
                      :color="colors.warningColor.iconColor"
                      left>
                    mdi-alert
                  </v-icon>
                  <v-icon v-else-if="violation.severity === 'ERROR'"
                      :color="colors.errorColor.iconColor"
                      left>
                    mdi-alert-circle
                  </v-icon>
                  <p v-html="$te(`messages.${violation.key}`)
                          ? $t(`messages.${violation.key}`)
                          : violation.message"></p>
                </li>
              </ul>
            </div>
            <div v-if="endElements.find(name => name.test(tagName)) || children.length === 0">
              <XMLContent :content="currentNode"></XMLContent>
            </div>
            <div v-else>
              <AbstractElement
                  v-for="(child, index) in children"
                  :key="index"
                  :colors="colors"
                  :currentNode="child"
                  :hideValid="hideValid"
                  :parentPath="path"
                  :validationResponse="validationResponse"></AbstractElement>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </transition>
    </v-expansion-panels>
  </div>
</template>

<script>
import XMLContent from '@/components/XMLContent';

export default {
  name: 'AbstractElement',
  components: {XMLContent},
  props: ['currentNode', 'validationResponse', 'parentPath', 'isDisabled', 'colors', 'hideValid'],
  data() {
    return {
      label: '',
      path: '',
      isValid: false,
      hasWarning: false,
      hasError: false,
      violationMessages: [],
      endElements: [/Organization/, /KeyDescriptor/, /Extensions/],
      elementColor: {
        bgColor: 'white',
        textColor: 'black',
        iconColor: 'rgba(255, 255, 255, 0)'
      }
    };
  },
  computed: {
    boxStyles() {
      if (this.isDisabled) {
        return {
          'background-color': this.colors.errorColor.bgColor,
          'color': this.colors.errorColor.textColor
        };
      } else {
        return {
          'background-color': this.elementColor.bgColor,
          'color': this.elementColor.textColor
        };
      }
    },
    warningStyle() {
      return {
        'background-color': this.colors.warningColor.bgColor,
        'color': this.colors.warningColor.textColor
      };
    },
    errorStyle() {
      return {
        'background-color': this.colors.errorColor.bgColor,
        'color': this.colors.errorColor.textColor
      };
    },
    children() {
      return this.currentNode.children;
    },
    tagName() {
      return this.currentNode.tagName;
    }
  },
  methods: {
    setInitialPath() {
      this.path = this.parentPath + '/' + this.currentNode.tagName;
    },
    resetValidity() {
      this.violationMessages = [];
      this.isValid = false;
      this.hasWarning = false;
      this.hasError = false;
    },
    formatView() {
      const {tagName, attributes} = this.currentNode;
      this.setInitialPath();
      this.label = tagName;

      if (/EntitiesDescriptor/.test(tagName)) {
        this.label = 'EntitiesDescriptor: Wrapper für die EntityDescriptors';
        this.elementColor = {
          bgColor: '#2e3238',
          textColor: '#f2f2f2'
        };
      } else if (/EntityDescriptor/.test(tagName)) {
        if (!attributes.entityID || attributes.entityID.value === '') {
          this.label = `EntityDescriptor: Deskriptor für eine Entität`;
          this.path += `[entityID='null']`;
        } else {
          this.label = `EntityDescriptor: Deskriptor für eine Entität<br>(${attributes.entityID.value})`;
          this.path += `[entityID='${attributes.entityID.value}']`;
        }

        this.elementColor = {
          bgColor: '#e7e4e4',
          textColor: 'black'
        };
      } else if (/IDPSSODescriptor/.test(tagName)) {
        this.label = 'IDPSSODescriptor: Deskriptor für den Identity-Provider';
        this.elementColor = {
          bgColor: '#f2f2f2',
          textColor: 'black'
        };
      } else if (/SPSSODescriptor/.test(tagName)) {
        this.label = 'SPSSODescriptor: Deskriptor für den Service-Provider';
        this.elementColor = {
          bgColor: '#f2f2f2',
          textColor: 'black'
        };
      } else if (/KeyDescriptor/.test(tagName)) {
        if (attributes.use) {
          if (attributes.use.value === 'signing') {
            this.label = 'KeyDescriptor: Public-Key für das Signatur<br>(mit Angabe Verwendungszweck: use "signing")';
          } else if (attributes.use.value === 'encryption') {
            this.label = 'KeyDescriptor: Public-Key für das Verschlüsselung<br>(mit Angabe Verwendungszweck: use "encryption")';
          }

          this.path += `[use='${attributes.use.value}']`;

        } else {
          this.label = 'KeyDescriptor';
          this.path += `[use='unspecified']`;
        }
        this.elementColor = {
          bgColor: '#4b525b',
          textColor: '#f2f2f2'
        };
      } else if (/Organization|Extensions|SingleLogoutService|SingleSignOnService|NameIDFormat|AssertionConsumerService/.test(
          tagName)) {
        this.label = tagName.slice(tagName.search(':') + 1);
        this.elementColor = {
          bgColor: '#4b525b',
          textColor: '#f2f2f2'
        };

        if (/SingleSignOnService|AssertionConsumerService/.test(this.label)) {
          let binding, location;

          if (!attributes.Binding || attributes.Binding.value === '') {
            binding = 'null';
          } else {
            binding = attributes.Binding.value;
          }

          if (!attributes.Location || attributes.Location.value === '') {
            location = 'null';
          } else {
            location = attributes.Location.value;
          }

          this.path += `[Binding='${binding}', Location='${location}']`;
        }

      } else if (/Attribute/.test(tagName)) {

        let name, friendlyName;

        if (!attributes.FriendlyName || attributes.FriendlyName.value === '') {
          this.label = tagName;
          friendlyName = 'null';
        } else {
          this.label = attributes.FriendlyName.value;
          friendlyName = attributes.FriendlyName.value;
        }

        if (!attributes.Name || attributes.Name.value === '') {
          name = 'null';
        } else {
          name = attributes.Name.value;
        }

        this.path += `[Name='${name}', FriendlyName='${friendlyName}']`;

        this.elementColor = {
          bgColor: '#6b7884',
          textColor: '#f2f2f2'
        };
      }
    },
    checkValidity() {
      if (this.validationResponse) {
        this.resetValidity();

        if (this.validationResponse.valid) {
          this.isValid = true;
          this.elementColor = this.colors.validColor;
          return;
        }

        for (let violation of this.validationResponse.violations) {

          if (violation.elementPath && violation.elementPath.includes(this.path)) {

            if (violation.severity === 'WARNING') {
              this.hasWarning = true;
            } else if (violation.severity === 'ERROR') {
              this.hasError = true;
            }

            if (violation.elementPath === this.path || /Organization|KeyDescriptor|Extensions/.test(
                this.path)) {
              this.violationMessages.push({
                key: violation.key,
                message: violation.message,
                severity: violation.severity
              });
            }
          }
        }
      }
    }
  },
  watch: {
    currentNode() {
      this.formatView();
      this.checkValidity();
    }
  },
  mounted() {
    this.formatView();
    this.checkValidity();
  }
};
</script>

<style scoped>
.abstract-element {
  width: 90%;
  margin: 0 auto;
  border-radius: 10px;
}

.box {
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 6px 1px rgba(34, 34, 34, 0.5);
  margin: 10px auto 5px;
  overflow: hidden;
}

.label {
  text-align: left;
  margin: 0;
  padding: 20px;
  border-radius: inherit;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.8px;
  transition: all 0.3s ease-in-out, border-radius 0s, color 0.1s;
}

.label:hover {
  background-color: rgba(19, 38, 57, 0.2);
  letter-spacing: 0.6px;
  cursor: pointer;
}

.label > span {
  margin-right: 20%;
  line-height: 1.3rem;
}

.label-icon {
  margin-left: 10px;
  padding: 0;
  background-image: radial-gradient(at 50% 55%, white 30%, transparent 30%);
  text-shadow: -1px 0 rgba(255, 220, 220, 0.8), 0 1px rgba(255, 220, 220, 0.8), 1px 0 rgba(255, 220, 220, 0.8), 0 -1px rgba(255, 220, 220, 0.8);
}

.message-box {
  display: flex;
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  border-radius: 6px;
  border: 1px inset rgba(100, 0, 0, 0.3);
  box-shadow: 0 2px 4px 1px rgba(34, 34, 34, 0.4);
  font-size: 0.9rem;
  letter-spacing: 0.4px;
}

.message-box p {
  width: 90%;
  margin: 0;
  word-break: break-word;
}

.collapse-enter,
.collapse-leave-to {
  height: 0;
  margin: 0;
  opacity: 0;
}

.collapse-enter-active,
.collapse-leave-active {
  transition: all 0.8s;
}
</style>
