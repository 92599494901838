<template>
  <div class="xml-content">
    <editor
        :value="formattedContent"
        height="90%"
        lang="xml"
        theme="tomorrow_night"
        width="90%"
        @init="editorInit"></editor>
  </div>
</template>

<script>
const beautify_html = require('js-beautify').html;

export default {
  name: 'XMLContent',
  components: {
    editor: require('vue2-ace-editor')
  },
  props: ['content'],
  computed: {
    formattedContent() {
      const formatOptions = {
        indent_size: 4,
        indent_char: ' ',
        wrap_line_length: 80,
        preserve_newlines: true,
        max_preserve_newlines: 1
      };

      // parses the content prop to string and formats it
      return beautify_html((new XMLSerializer()).serializeToString(this.content), formatOptions);
    }
  },
  methods: {
    editorInit: function (editor) {
      require('brace/ext/language_tools');
      require('brace/mode/xml');
      require('brace/theme/tomorrow_night');

      // add padding within the editor
      editor.renderer.setScrollMargin(8, 8);

      // make the editor readonly, hide the cursor and line highlights
      editor.renderer.$cursorLayer.element.style.display = 'none';
      editor.setOptions({
        readOnly: true,
        highlightActiveLine: false,
        highlightGutterLine: false,
        maxLines: 30
      });
    }
  }
};
</script>

<style scoped>
.xml-content {
  padding: 10px 0 20px;
}

.ace_editor {
  margin: 0 auto;
  border-radius: 5px;
}
</style>
