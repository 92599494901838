<template>
  <v-app>
    <div id="app" :class="{'blur-content': blur}">
      <PageHeader></PageHeader>
      <div id="app-content">
        <TheDisclaimer @close="closeDialog"></TheDisclaimer>
        <InputArea @validate="handleValidate"></InputArea>
        <ValidationResults
            v-if="validationResponse"
            :validationResponse="validationResponse"></ValidationResults>
      </div>
    </div>
  </v-app>
</template>

<script>
import InputArea from '@/components/InputArea';
import ValidationResults from '@/components/ValidationResults';
import PageHeader from "@/components/PageHeader";
import TheDisclaimer from "@/components/TheDisclaimer";

export default {
  name: 'App',
  components: {
    PageHeader,
    ValidationResults,
    InputArea,
    TheDisclaimer
  },
  data() {
    return {
      validationResponse: '',
      blur: true
    };
  },
  methods: {
    handleValidate(validationResponse) {
      this.validationResponse = validationResponse;
    },
    closeDialog(show) {
      this.blur = show;
    }

  }
};
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: whitesmoke;
}

h1 {
  text-align: center;
}

#app {
  font-family: 'Rubik', 'Roboto', sans-serif;
  letter-spacing: 0.8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.8);
}

#app-content {
  margin-top: 80px;
  padding: 2% 10%;
}

.blur-content {
  filter: blur(6px);
}

/*scrollbar styles for chrome, edge*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  width: 20px;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(166, 166, 166, 0.5);
  border-radius: 6px;
}

/* firefox only */
.ace_scrollbar {
  scrollbar-color: rgba(166, 166, 166, 0.5) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}

/* media queries */
@media (max-width: 1500px) {
  #app-content {
    padding: 2% 5%;
  }
}
</style>
