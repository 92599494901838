<template>
  <div class="input-area">
    <h3>{{ $t('page.titles.metadata-to-validate') }}</h3>
    <editor
        v-model="xmlInput"
        height="500px"
        lang="xml"
        theme="tomorrow_night"
        width="100%"
        @init="editorInit"></editor>
    <button @click="validate">{{ $t('page.buttons.validate') }}</button>
    <div v-show="error" class="error-text">
      <v-icon class="icon" color="#b4182d">mdi-alert-circle</v-icon>
      <span>{{ $t('page.titles.error') }}: {{ $t(error) }}</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import getEnv from '@/utils/env';

const beautify_html = require('js-beautify').html;

export default {
  name: 'InputArea',
  components: {
    editor: require('vue2-ace-editor')
  },
  data() {
    return {
      xmlInput: '',
      error: '',
      backendURL: getEnv('VUE_APP_BACKEND_URL')
    };
  },
  methods: {
    editorInit: function (editor) {
      require('brace/ext/language_tools');
      require('brace/mode/xml');
      require('brace/theme/tomorrow_night');

      // add padding within the editor
      editor.renderer.setScrollMargin(8, 8);
    },
    validate() {
      if (this.xmlInput === '') {
        this.error = 'messages.cannot-be-empty';
        return;
      } else if (this.xmlInput.length < 500) {
        this.error = 'messages.too-short';
        return;
      }

      const config = {
        headers: {'Content-Type': 'application/xml'}
      };
      axios.post(this.backendURL, this.xmlInput, config)
      .then((response) => {
        this.error = '';
        this.$emit('validate', response.data);
      })
      .catch((err) => {
        this.error = err.message;
      });

      this.formatXML();
    },
    formatXML() {
      const formatOptions = {
        indent_size: 4,
        indent_char: ' ',
        wrap_line_length: 80,
        preserve_newlines: false
      };

      this.xmlInput = beautify_html(this.xmlInput, formatOptions);
    }
  },
  watch: {
    error() {
      if (this.error) {
        this.$emit('validate', '');
      }
    }
  }
};
</script>

<style scoped>
h3 {
  margin: 20px 0;
}

.input-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.icon {
  margin: 0 10px 0 0;
  padding: 0;
}

.error-text {
  margin: 5px 10px;
  padding: 5px 0;
  font-size: 0.9rem;
  letter-spacing: 0.4px;
  list-style: none;
  color: #ca1c33;
}

.ace_editor {
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.7);
}

button {
  align-self: center;
  margin: 20px;
  padding: 15px 30px;
  border: none;
  border-radius: 30px;
  background-color: #4b525b;
  color: #f2f2f2;
  font-weight: 600;
  font-size: 16px;
  font-family: inherit;
  letter-spacing: inherit;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.6);
  transition: scale 0.1s ease-in-out, letter-spacing 0.3s ease-in-out, background-color 0.3s;
}

button:hover {
  background-color: #2e3238;
  letter-spacing: 0.6px;
  cursor: pointer;
}

button:active {
  transform: scale(0.96);
}
</style>
